import React, { Component, useState } from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import Footer from "./components/Footer";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import Header2 from "./components/Header2";
import Header from "./components/Header";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { BiWinkSmile } from "react-icons/bi";
import TopBarProgress from "react-topbar-progress-indicator";
import Cookies from "universal-cookie";
import Modal from "react-bootstrap/Modal";
const cookies = new Cookies();
if (!cookies.get("clientid")) {
  cookies.set("clientid", uuidv4().toUpperCase());
} else {
}

// alert(cookies.get('clientid'));

const uuid = uuidv4().toUpperCase();
const uuid1 = uuidv4().toUpperCase();
const uuid2 = uuidv4().toUpperCase();
const uuid3 = uuidv4().toUpperCase();
const uuid4 = uuidv4().toUpperCase();
const uuid5 = uuidv4().toUpperCase();
const uuid6 = uuidv4().toUpperCase();

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      products: [],
      currentEvent: [],
      provinces: [],
      eventcategory: [],
      msg: "",
      boolean: true,
      spinning: "flex",
      email: "",
      phone: "",
      customername: "",
      loadBarProgress: true,
      loadProvinces: true,
      loadCategories: true,
      loadMoreLink: false,
      affiliate: "",
      resumeModal: false,
      detailModal: false,
      spin: "none",
      updateDisplay: "block",
      affiliate_email: "",
      affiliate_phone: "",
    };
  }

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const affiliateid = query.get("affiliateid");

    if (!localStorage.getItem("affiliateid"))
      localStorage.setItem("affiliateid", affiliateid);

    document.title = "Karamale - World's digital ticket software";
    // alert(localStorage.getItem("affiliateid"));

    if (localStorage.getItem("affiliateid") !== "null") {
      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=AFFILIATEDETAILS&affiliateid=" +
          localStorage.getItem("affiliateid")
      ).then((response) => {
        this.setState({
          affiliate: response.data.affiliate,
          affiliate_email: response.data.email,
          affiliate_phone: response.data.phone,

          resumeModal: true,
        });
      });
    }

    if (localStorage.getItem("customerid") !== null) {
      const customerid = localStorage.getItem("customerid");
      Axios.get(
        "https://karamale.com/apps/kscanner/webservices.php?query=CUSTOMERDETAILS&accountid=" +
          customerid
      ).then((response) => {
        this.setState({
          email: response.data.email,
          phone: response.data.phone,
          customername: response.data.customername,
        });
      });
    } else {
      this.setState({
        email: "",
        phone: "",
        customername: "",
      });
    }
    const apiUrl =
      "https://karamale.com/apps/kscanner/webservices.php?query=EVENTS";
    const apiUrlCur =
      "https://karamale.com/apps/kscanner/webservices.php?query=EVENTS";

    fetch(apiUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            products: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );

    fetch(apiUrlCur)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            currentEvent: result,
            boolean: false,
            spinning: "none",
            loadBarProgress: false,
          });
        },
        (err) => {
          this.setState({ err });
        }
      );

    axios
      .get("https://karamale.com/apps/kscanner/webservices.php?query=PROVINCES")
      .then((response) => {
        this.setState({
          provinces: response.data,
          spinning: "none",
          loadProvinces: false,
        });
      });

    axios
      .get(
        "https://karamale.com/apps/kscanner/webservices.php?query=EVENTCATEGORY&limit=9"
      )
      .then((response) => {
        this.setState({
          eventcategory: response.data,
          spinning: "none",
          loadCategories: false,
          loadMoreLink: true,
        });
      });
  }

  cancel = () => {
    this.setState({
      resumeModal: false,
      detailModal: false,
    });
  };

  details = () => {
    this.setState({ resumeModal: false, detailModal: true });
  };

  back = () => {
    this.setState({ resumeModal: true, detailModal: false });
  };

  signUp = () => {
    this.props.history.push({
      pathname: "/business",
    });
  };

  learnMore = () => {
    this.props.history.push({
      pathname: "/aboutkaramale",
    });
  };

  render() {
    const { error, products } = this.state;
    const { err, currentEvent } = this.state;
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const fulldate = year + "-" + month + "-" + day;
    let { event, customername, accounttype } = "";
    let { customerid, accountid } = 0;

    if (localStorage.getItem("customerid") !== null) {
      accountid = localStorage.getItem("customerid");
      customerid = localStorage.getItem("customerid");
      accounttype = localStorage.getItem("accounttype");
      customername = localStorage.getItem("customername");
    } else {
      accountid = 0;
      customerid = 0;
      accounttype = "";
      customername = "";
    }

    let display = "";
    const { loadBarProgress, loadProvinces, loadCategories, loadMoreLink } =
      this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else {
      return (
        <div className="view">
          {localStorage.getItem("customername") !== null ? (
            <Header2 />
          ) : localStorage.getItem("businessname") !== null &&
            localStorage.getItem("accounttype") !== null ? (
            <Redirect to="/dashboard" />
          ) : localStorage.getItem("partnerid") !== null ? (
            <Redirect to="/in" />
          ) : (
            <Header />
          )}

          <div className="container mt-0 p-0">
            <Modal show={this.state.detailModal} onHide={this.closeModal}>
              <div className="col-12 pt-4 pb-2 pl-4 pr-4">
                <div className="row justify-content-center align-self-center">
                  <div className="col-12 text-center mb-4">
                    <label className="small-details mb-0">
                      <label className="mb-0 small-details font-size-13">
                        <span className="font-size-20">
                          {this.state.affiliate}
                        </span>
                        <br />
                        <span>Email:</span>{" "}
                        <Link
                          to={{
                            pathname: "mailto:" + this.state.affiliate_email,
                          }}
                          className="color-blue"
                          target="_blank"
                        >
                          {this.state.affiliate_email}
                        </Link>
                        <br />
                        <span>Phone: </span>{" "}
                        <Link
                          to={{
                            pathname: "tel:" + this.state.affiliate_phone,
                          }}
                          className="color-blue"
                          target="_blank"
                        >
                          {this.state.affiliate_phone}
                        </Link>
                      </label>
                    </label>
                  </div>

                  <div
                    className="save-btn btn-radius-3 mb-4 mr-2"
                    onClick={this.back}
                  >
                    <span>Back</span>
                  </div>

                  <div
                    className="cancel-btn btn-radius-3 ml-2"
                    onClick={() => this.cancel()}
                    style={{ height: "2.7em" }}
                  >
                    Close
                  </div>
                </div>
              </div>
            </Modal>

            <Modal show={this.state.resumeModal} onHide={this.closeModal}>
              <div className="col-12 pt-4 pb-2 pl-4 pr-4">
                <div className="row justify-content-center align-self-center">
                  <div className="col-12 text-center mb-4">
                    <label className="small-details mb-0">
                      <label className="mb-0 small-details font-size-13">
                        You were referred by{" "}
                        <label className="color-blue" onClick={this.details}>
                          {this.state.affiliate}
                        </label>
                        <br />
                        <br />
                        Sign up or learn more about Karamale ticket software.
                      </label>
                    </label>
                  </div>

                  <div
                    className="save-btn btn-radius-3 mb-4 mr-2"
                    onClick={this.signUp}
                  >
                    <span>Sign up</span>
                  </div>

                  <div
                    className="cancel-btn btn-radius-3"
                    onClick={this.learnMore}
                    style={{ height: "2.7em", width: 110 }}
                  >
                    Learn more...
                  </div>

                  <div
                    className="cancel-btn btn-radius-3 ml-2"
                    onClick={() => this.cancel()}
                    style={{ height: "2.7em" }}
                  >
                    Close
                  </div>
                </div>
              </div>
            </Modal>

            <div className="row mt-3 w-100">
              {localStorage.getItem("customername") !== null ? (
                ""
              ) : (
                <div className="row w-100 pl-3 pr-3">
                  <div class="col-lg-3"></div>
                  <div
                    class="col-lg-9 col-12 affiliate-header-text p-0"
                    style={{ textTransform: "none", padding: 0 }}
                  >
                    Want to sell tickets on Karamale?{" "}
                    <Link to="/budget" className="link-color">
                      See how much it cost.{" "}
                      <BiWinkSmile size={25} color={"#b2b200"} />
                    </Link>
                    <br />
                    <Link to="/aboutsalesparnter">
                      <label className="color-blue font-normal font-size-15 p-0 mb-0">
                        <label className="affiliate-header-text font-normal font-size-15 p-0 mb-0">
                          <span>Want to Earn money? </span>Join Karamale
                          affiliate program.{" "}
                          <span className="color-blue font-normal">
                            Learn more...
                          </span>
                        </label>
                      </label>
                    </Link>
                  </div>
                </div>
              )}

              <div className="col-lg-3 left-navigation  house-container pl-0 pr-0">
                <div className="col-lg-12 pt-4 pr-0 pl-0">
                  <div className="row">
                    <div className="col-12 name-events pl-3 pt-0">
                      Filter By
                    </div>
                    <div className="col-lg-12 col-6 province-pads">
                      <div className="heading-container mb-4 pb-2">
                        <div className="heading">Provinces</div>
                        {loadProvinces && (
                          <div className="col-12 text-center justify-content-center mt-4 mb-4">
                            <ClipLoader
                              color={"red"}
                              loading={true}
                              // css={override}
                              size={20}
                            />
                          </div>
                        )}
                        <div className="col-12 p-2">
                          {this.state.provinces.map((items, index) => {
                            return (
                              <Link
                                to={{
                                  pathname: "/explore",
                                  state: {
                                    fromHome: true,
                                    provinceid: items.provinceid,
                                    category: items.category,
                                    province: items.province,
                                  },
                                }}
                                className="eventLinks"
                              >
                                <div className={"provinces"} key={index}>
                                  {items.province}
                                </div>
                              </Link>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-6 category-pads">
                      <div className="heading-container pb-2">
                        <div className="heading">Categories</div>
                        {loadCategories && (
                          <div className="col-12 text-center justify-content-center mt-4  mb-4">
                            <ClipLoader
                              color={"red"}
                              loading={true}
                              // css={override}
                              size={20}
                            />
                          </div>
                        )}
                        <div className="col-12 p-2">
                          {this.state.eventcategory.map((items, index) => {
                            return (
                              <Link
                                to={{
                                  pathname: "/category",
                                  state: {
                                    fromHome: true,
                                    provinceid: items.provinceid,
                                    category: items.category,
                                    province: items.province,
                                  },
                                }}
                                className="eventLinks"
                              >
                                <div className={"provinces"} key={index}>
                                  {items.category}
                                </div>
                              </Link>
                            );
                          })}
                          <Link
                            to={{
                              pathname: "/categories",
                            }}
                            className="eventLinks"
                          >
                            {loadMoreLink && (
                              <div className={"provinces more-cat"}>
                                More...
                              </div>
                            )}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 middle-content house-event pl-0 pr-0">
                <div className="row mt-3">
                  {loadBarProgress && (
                    <div className="col-12 text-center justify-content-center">
                      {/* <ClipLoader
                      color={"red"}
                      loading={true}
                      // css={override}
                      size={30}
                    /> */}
                      <SkeletonTheme color="#BEC3C9" highlightColor="444">
                        <p>
                          <Skeleton
                            count={200}
                            width={100}
                            height={10}
                            delay={1}
                          />
                        </p>
                      </SkeletonTheme>
                    </div>
                  )}
                  {loadBarProgress && <TopBarProgress />}

                  {/* {this.state.msg} */}
                  <div className="col-12 name-events">Events</div>

                  {currentEvent.map((item, index) => {
                    const posterUri =
                      "https://karamale.com/apps/kscanner/posters/" +
                      item.businessid +
                      "/" +
                      item.eventid +
                      "/" +
                      item.poster;

                    if (item.balance < 6 && item.event_day > fulldate) {
                      display = "none";
                    } else {
                      display = "block";
                    }

                    return (
                      <div
                        className="col-lg-3 col-md-4 col-sm-6 col-6 mb-1 h-100 p-2"
                        style={{ display: display }}
                      >
                        <Link
                          // data-tip={item.event_name}
                          to={{
                            pathname: "/event",
                            search:
                              "q=" +
                              uuid6 +
                              "&queryid=" +
                              uuid5 +
                              "&pageid=" +
                              uuid4 +
                              "&uid=" +
                              uuid3 +
                              "&qKey=" +
                              uuid1 +
                              "&key=" +
                              uuid2 +
                              "&uuid=" +
                              uuid +
                              "&eventid=" +
                              item.eventid +
                              "&eventDate=" +
                              item.event_day +
                              "&fulldate=" +
                              fulldate +
                              "&customerid=" +
                              customerid +
                              "&posterUri=" +
                              posterUri +
                              "&accountid=" +
                              accountid +
                              "&accounttype=" +
                              accounttype +
                              "&customername=" +
                              this.state.customername +
                              "&email=" +
                              this.state.email +
                              "&phone=" +
                              this.state.phone +
                              "&venue=" +
                              item.event_location +
                              "&description=" +
                              item.event_des +
                              "&businessid=" +
                              item.businessid +
                              "&poster=" +
                              item.poster +
                              "&eventname=" +
                              item.eventname +
                              "&eventday=" +
                              item.eventday +
                              "&starttime=" +
                              item.start_time +
                              "&endtime=" +
                              item.finish_time +
                              "&previousPage=&campaignStatus=running&category=",
                            state: {
                              fromHome: true,
                              eventid: item.eventid,
                              eventDate: item.event_day,
                              fulldate: fulldate,
                              customerid: customerid,
                              posterUri: posterUri,
                              accountid: accountid,
                              accounttype: accounttype,
                              // customername: customername,
                              customername: this.state.customername,
                              email: this.state.email,
                              phone: this.state.phone,
                              venue: item.event_location,
                              description: item.event_des,
                              businessid: item.businessid,
                              poster: item.poster,
                              eventname: item.eventname,
                              eventday: item.eventday,
                              starttime: item.start_time,
                              endtime: item.finish_time,
                              previousPage: "",
                              campaignStatus: "running",
                              category: "",
                            },
                          }}
                          className="eventLinks"
                        >
                          <div className="nainer">
                            <div
                              className="pt-0 text-center justify-content-center align-items-center"
                              style={{ display: "flex" }}
                            >
                              <div
                                style={{
                                  backgroundImage:
                                    "url(https://karamale.com/apps/kscanner/posters/" +
                                    item.businessid +
                                    "/" +
                                    item.eventid +
                                    "/" +
                                    item.poster +
                                    ")",
                                  height: 120,
                                  backgroundPosition: "center",
                                  backgroundSize: "cover",
                                  borderRadius: 5,
                                  borderBottomLeftRadius: 0,
                                  borderBottomRightRadius: 0,
                                  width: "100%",
                                }}
                              ></div>
                            </div>
                            <div className="eventName pl-3 pr-3">
                              <strong className="textWeight">
                                {item.event_name.length > 13
                                  ? (event =
                                      item.event_name.substr(0, 13) + "...")
                                  : (event = item.event_name)}
                              </strong>
                              <br />
                              {item.eventDate}
                              <div className="time">
                                {item.eventday}
                                <br />
                                <span className="at">At {item.start_time}</span>
                                <br /> <br />
                                <span>Category</span>
                                <br />
                                <span className="category-name">
                                  {item.eventcategory}
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* <ReactTooltip /> */}
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      );
    }
  }
}

export default Home;
